import Head from 'next/head';
import Script from 'next/script';

/*
 * https://developers.google.com/search/docs/appearance/structured-data/breadcrumb?hl=ko 를 보고 서브링크 SEO를 위해 다음 Script tag를 추가합니다. */
export const Breadcrumbs = () => {
  return (
    <Head>
      <Script type='application/ld+json'>
        {`
         {
           "@context": "https://schema.org/", 
           "@type": "BreadcrumbList", 
           "itemListElement": [{
             "@type": "ListItem", 
             "position": 1, 
             "name": "홈",
             "item": "https://drivingteacher.co.kr"  
           },{
             "@type": "ListItem", 
             "position": 2, 
             "name": "예약",
             "item": "https://drivingteacher.co.kr/explore"  
           },{
             "@type": "ListItem", 
             "position": 3, 
             "name": "필기",
             "item": "https://drivingteacher.co.kr/test"  
           },{
             "@type": "ListItem", 
             "position": 4, 
             "name": "블로그",
             "item": "https://drivingteacher.co.kr/blog"  
           },{
             "@type": "ListItem", 
             "position": 5, 
             "name": "내정보",
             "item": "https://drivingteacher.co.kr/profile"  
           }]
         }
       `}
      </Script>
    </Head>
  );
};
